import { reactive, computed, ref, Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import dataQualityReportService from '@100-m/hauru/src/services/DataQualityReportService.js'
import templateService from '@100-m/hauru/src/services/TemplateService'
import { DataReportDefinition, DataQualityReportDefinition } from '../builder'
import { useDrVariables, defaultVariableSettings } from '@100-m/hauru/src/components/form/drVariables/lib'

export interface BuilderContext {
  variables?: Record<string, any>
  translations?: Record<string, string>
  t: Record<string, string>
}

const dataReports: Ref<DataReportDefinition[]> = ref([])
const dqcReports: Ref<DataQualityReportDefinition[]> = ref([])
const templates: Ref<string[]> = ref([])

async function getTranslations(lang: string, variables: Record<string, any>) {
  const { data, error } = await dataReportService.run('drTranslations', { lang })
  // NOTE: we could do this to have the app translations, but for now it only returns the eng trads ?
  // const translations = { ...$root.t, ...data }
  const translations = data?.result
  Object.keys(variables)
    .filter(d => d.startsWith('axis_'))
    .forEach((axis: string) => {
      // @ts-ignore
      translations[axis] = translations[variables[axis]] || variables[axis]
    })

  const dynamicTranslations: Record<string, string> = {
    fundName_dynamic: 'fundName',
    shareName_dynamic: 'share_name',
    benchmarkName_dynamic: 'benchmarkName',
  }
  Object.keys(dynamicTranslations).forEach((key: string) => {
    translations[key] = 'translations.' + key
  })
  return translations
}
const context: BuilderContext = reactive({
  variables: {},
  translations: {},
  t: {},
})
// watch(dataReportVariables, async () => {
//   if (!dataReportVariables.value.lang) return
//   context.variables = { ...variables, ...dataReportVariables.value }
//   context.translations = await getTranslations(dataReportVariables.value.lang, dataReportVariables.value)
//   const handler = {
//     get(target: any, prop: string) {
//       return target[prop] || prop
//     },
//   }
//   context.t = new Proxy(context.translations, handler)
//   console.log('Updating context')
// })
async function updateContext(variables: Record<string, any>, variableContext: Record<string, any>) {
  context.variables = { ...variables, ...variableContext }
  if (variableContext.lang) {
    context.translations = await getTranslations(variableContext.lang, variables)
    const handler = {
      get(target: any, prop: string) {
        return target[prop] || prop
      },
    }
    context.t = new Proxy(context.translations, handler)
  }
}
export async function getDataReports() {
  const _dataReports = await dataReportService.getAll()
  return _dataReports.filter((dr: any) => typeof dr.id === 'number' || dr.id.includes('factsheet'))
}

async function initBuilder() {
  // Disable usage of built-in data report service (to prevent migration or change in the product)
  dataReports.value = (await getDataReports()).filter((d: DataReportDefinition) => typeof d.id === 'number')
  dqcReports.value = await dataQualityReportService.all()

  const _templates = await templateService.findMany()
  templates.value = _templates.map(t => t.name)

  // await initVariablesOptions()
  return { dataReports, templates, dqcReports }
}

// Note we dont really need to export this function, we could just use the variables directly,
// keeping it for composables consistency
export default function useBuilderContext({ onComplete, onError }: { onComplete?: Function; onError?: Function }) {
  const router = useRouter()
  const route = useRoute()
  function setUrlVariables(v: Record<string, any>) {
    function updateQuery(query: Record<string, string>) {
      router.push({ query: { ...route.query, ...query } })
    }
    updateQuery(v)
  }
  function onChange(variables: any, variableContext: any) {
    setUrlVariables(variables)
    updateContext(variables, variableContext)
  }
  const { variables, updateVariable, partials, inputData, missingDependencies, initDrVariables, runParameters } =
    useDrVariables({ onComplete, onChange, onError })
  const variableInputs = computed(() => partials.value.concat(runParameters.value))
  function initDataReport(dataReport: DataReportDefinition, variables: Record<string, any> = {}) {
    // Back compat: use defaultVariableSettings if no settingVariableParameters in the data report
    initDrVariables(dataReport.settingVariableParameters || defaultVariableSettings, {
      ...dataReport.variables,
      ...route.query,
      ...variables,
    })
  }
  return {
    initBuilder,
    initDataReport,
    variableInputs,
    updateVariable,
    missingDependencies,
    inputData,
    dataReports,
    dqcReports,
    templates,
    context,
    variables,
  }
}
