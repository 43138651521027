import axios from 'axios'
import config from '../config'

export const partialToQl = partial =>
  Object.entries(partial).reduce((str, b) => `${str}\n ${b[0]}: ${JSON.stringify(b[1])}`, '')

/**
 * Rest base service
 */
export class RestService {
  /**
   * @param enpoint The REST route of the API
   * @param payload The JSON payload to send
   * @return {Promise<any>} Any response
   */

  async post(enpoint, payload, options = {}) {
    try {
      const res = await axios.post(`${config.graphqlEndpoint}/${enpoint}`, payload, {
        headers: {
          // NOTE: authorization is already injected by an axios interceptor
        },
        ...options,
      })
      return res?.data
    } catch (error) {
      throw new Error(error.message)
    }
  }

  async get(enpoint, options = {}) {
    try {
      const res = await axios.get(`${config.graphqlEndpoint}/${enpoint}`, {
        headers: {
          // NOTE: authorization is already injected by an axios interceptor
        },
        ...options,
      })
      return res?.data
    } catch (error) {
      throw new Error(error.message)
    }
  }

  async delete(enpoint, options = {}) {
    try {
      const res = await axios.delete(`${config.graphqlEndpoint}/${enpoint}`, {
        headers: {
          // NOTE: authorization is already injected by an axios interceptor
        },
        ...options,
      })
      return res?.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

export const downloadResponseAsFile = (response, type, name) => {
  const downloadElement = document.createElement('a')
  downloadElement.style.display = 'none'
  downloadElement.href = window.URL.createObjectURL(new Blob([response], { type }))
  downloadElement.download = name
  document.body.appendChild(downloadElement)
  downloadElement.click()
  window.URL.revokeObjectURL(downloadElement.href)
  downloadElement.remove()
}
